import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = []

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Page Samples",
  path: "react-page-samples",
}

const ReactPageSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactPageSamples
